import React, { useEffect, useState, useContext} from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, AgroSpinner } from 'agrofy-ui-components';
import styled, { css } from 'styled-components';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import MainCardLive from '../../molecules/MainCardLive/MainCardLive';
import withSSR from '../../withSSR';
import getData from '../../services/GetData';
import { useStore } from '../../hooks/store'
import AdhesionBanner from '../../atoms/AdhesionBanner/AdhesionBanner';
import PopUpBanner from '../../atoms/PopUpBanner/PopUpBanner';
import Logo from '../../images/icons/logo-agrofynews-iu.svg';
import { buildBreadcrumbJson } from '../../utils/breadcrumbJson';
import { splitUrl, strToLowerCase } from '../../utils/stringUtils';
import Breadcrumb from '../../atoms/Breadcrumb/Breadcrumb';
import { structuredVideoData } from '../../utils/StructuredVideoData';
import { gtmInitialize, manageGTMPageName, manageGTMUserStatus } from '../../utils/GTMDataLayerFuncs';
import { AgroPushNotification } from '../../components/AgroPushNotification/AgroPushNotification';
import { useTranslation } from 'react-i18next';
import UserContext from '../../atoms/UserContext/UserContext';
import SectionVideosTemplate from "../../templates/sectionVideosTemplate/SectionVideosTemplate";
import PaginationAgrofy from '../../atoms/PaginationAgrofy/PaginationAgrofy';

const AgrofyNewsLive = (props) => {
  const idVideo = props && props.match && props.match.params && props.match.params.idVideo;
  const dataVideos = props && props.serverVideos ? props.serverVideos : false;
  const dataNoticias = props && props.serverNoticias ? props.serverNoticias : false;
  const [updDataNoticias, setUpdDataNoticias] = useState(dataNoticias || null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);
  const amount = (dataNoticias && dataNoticias.pages);
  const [featuredVideo, setFeaturedVideo] = useState(null);
  const [videosData, setVideosData] = useState({ 1: null, 2: null, 3: null, 4: null });
  const [state, dispatch] = useStore();
  const [banner1Loaded, setBanner1Loaded] = useState('no-loaded-banner');
  const [lightVideo, setLightVideo] = useState(true);
  const [isLoading, setIsLoading] = useState(dataNoticias || false);
  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === "Brasil";
  const dfpNetworkID = process.env.DFP_NETWORK_ID || typeof window !== 'undefined' && window.env.DFP_NETWORK_ID;
  const pathName = props && props.location && props.location.pathname;
  const playingVideo = state && state.videoData;
  const templateBanners = [
    {
      adUnit: 'agrofytv_middle_1',
      sizes: [[728, 90], [300, 250], [320, 50]]
    },
    {
      adUnit: 'agrofytv_middle_2',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'agrofytv_middle_3',
      sizes: [[300, 250], [320, 50]]
    },
    {
      adUnit: 'agrofytv_filmstrip',
      sizes: [[300, 600]]
    }
  ];

  // GTM EVENT ?
  const { mkpUserData, userLoading } = useContext(UserContext);
  useEffect(() => {
    if (!userLoading) {
      manageGTMUserStatus(mkpUserData);
    }
  }, [userLoading]);

  // GTM INITIALIZE
  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        gtmInitialize();
      }, 3000)
    };
  }, []);

  // GTM EVENT - PAGENAME
  useEffect(() => {
    if (window && dataVideos) {
      manageGTMPageName('Home Agrofy News Live');
    };
  }, [dataVideos]);

  // SEO BREADCRUMB JSON 
  const breadcrumbArr = [
    { level: 'Agrofy News' },
    { level: 'Live', urlLevel: splitUrl(pathName, 1) },
    playingVideo && idVideo && { level: playingVideo.title, urlLevel: pathName.replace(/^\/|\/$/g, '') }
  ];

  // VISUAL BREADCRUMB
  const breadcrumb = [
    {
      title: "Live",
      link: '/live'
    },
    playingVideo && idVideo &&
    {
      title: playingVideo.title,
      link: playingVideo.urlSlug
    }
  ];

  //GET NEWS DATA FOR PAGINATION 
  const fetchData = () => {
    const url = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
    setIsLoading(true)
    return getData({ url: `${url}/api/News/GetNewsListByTag?tag=tags/agrofy-news-live&page=${currentPage - 1}&limit=15` })
      .then(result => {
        setUpdDataNoticias(result.data)
        setIsLoading(false)
      });
  };

  useEffect(() => {
    if (updDataNoticias || dataNoticias) {
      const section = document.getElementById('section-template');
      setIsLoading(false);
      if (section && ((dataNoticias !== updDataNoticias) || (currentPage !== previousPage))) {
        setPreviousPage(currentPage);
        fetchData();
        document.getElementById('section-template').scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [currentPage]);

  // SEO TEXTS
  const seoTitle = "Live, IU News | Agrofy News ";
  const seoDesc = "Entrevistas en profundidad, vivos, nuestro streaming en IU.News y todas las producciones especiales en video de Agrofy News";
  const seoUrl = "https://news.agrofy.com.ar/live";

  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" /> 
        <title>{seoTitle}</title>
        <meta name="robots" content="noindex,nofollow" />
        <meta name="description" content={seoDesc} data-rh="true"/>
        <link rel="canonical" href={strToLowerCase(seoUrl)} />
        <meta property="og:url" content={seoUrl} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:image" content={''} />
        <meta property="og:description" content={seoDesc} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content={isBrasil ? '' : '@AgrofyNews'} />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDesc} />
        <script type="application/ld+json">{buildBreadcrumbJson(breadcrumbArr)}</script>
        <script src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <script type="application/ld+json">{idVideo && playingVideo && structuredVideoData(playingVideo, false)}</script>
      </Helmet>
      <AgroPushNotification location="Section" />
      <section className="dfp-banner stripped container mb-20 top-banner">
        <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
          <AdSlot adUnit={'agrofytv_header'} sizes={[[970, 90], [970, 250], [320, 80]]} />
        </DFPSlotsProvider>
      </section>
      <section className="breadcrumb-wrapper p-0 mt-20" style={{ maxWidth: "1200px", margin: "0 auto", marginTop: "20px" }}>
        <Breadcrumb breadcrumbLinks={breadcrumb} />
      </section>
      <LogoSection>
        <ContainerLogo><div></div><div><img src={Logo} /></div></ContainerLogo>
      </LogoSection>
      <MainVideoSection className="fluid-container">
        <MainCardLive isCategoryPage={false} videoData={dataVideos} /> 
      </MainVideoSection>
      <div className="container px-0">
        <section id="main-section" className="d-flex flex-wrap mt-20">
          <div className="fluid-container col-lg-9 px-0">
            <section className="tags-page-title container mt-15">
              <div className="tags-page-div">
                <p>{seoDesc}</p>
              </div>
              <SectionVideosTemplate
                data={updDataNoticias || dataNoticias}
                classes="small-spacing-top"
                bannersData={templateBanners}
                loading={isLoading}
              ></SectionVideosTemplate>
              <div className="mb-36">
                {currentPage && amount &&
                  <PaginationAgrofy
                    parentCallback={setCurrentPage}
                    currentPage={currentPage}
                    amount={amount}
                  />
                }
              </div>
            </section>
          </div>
          <div className="row col-lg-3 sidebar-item px-0 dfp-banner stripped p-0 banner-section d-none d-lg-block">
            <div className="make-me-sticky">
              <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                <AdSlot adUnit={'agrofytv_followme'} sizes={[[260, 600]]} />
              </DFPSlotsProvider>
            </div>
          </div>
        </section>
      </div>
      {/*<AdhesionBanner adUnit='agrofytv_adhesion' sizes={[[728, 90], [320, 80]]} />
      <PopUpBanner adUnit='agrofytv_fullscreen' sizes={[[800, 600], [320, 480]]} />*/}
    </React.Fragment >
  )
}

AgrofyNewsLive.getInitialProps = async ({ match, req, res }) => {
  const urlNews = process.env.RAZZLE_ENDPOINT_NEWS_API || window.env.RAZZLE_ENDPOINT_NEWS_API;
  const urlNoti = process.env.RAZZLE_ENDPOINT_NOTI_API || window.env.RAZZLE_ENDPOINT_NOTI_API;
  
  try {
    const [videos, noticias] = await Promise.all([
      getData({ url: `${urlNews}/api/AgrofyTV/GetVideosListByPlaylistIDStreaming?playlistID=PLgR_sLej6ylxhbgHUxDdcKqX7F3bw7pzU&size=8` }),
      getData({ url: `${urlNoti}/api/News/GetNewsListByTag?tag=tags/agrofy-news-live&page=0&limit=15` }),
    ]);
    return {
      serverVideos: videos.data,
      serverNoticias: noticias.data,
    };    
  } catch (e) {
    return console.error(e);
  }
};

export default withSSR(AgrofyNewsLive);

export const LogoSection = styled.section`
    background: linear-gradient(90deg,rgba(63,233,213,1) 0%,rgba(121,9,162,1) 46%,rgba(213,50,73,1) 98%);
    display: flex;
    padding: 8px 0;
    margin-top: 20px;
    img {
        width: 100px;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}){
            display: block;
            padding: 12px 0 8px 0;
            background: linear-gradient(90deg,rgba(63,233,213,1) 0%,rgba(121,9,162,1) 46%,rgba(213,50,73,1) 98%);
            img {
                width: 310px;
            }
        }
    `}
    @media only screen  and (min-width : 1824px) {
        background: linear-gradient(90deg,rgba(63,233,213,1) 0%,rgba(121,9,162,1) 46%,rgba(213,50,73,1) 98%);
    }
    @media only screen  and (max-width: 1000px) {
        background: linear-gradient(90deg,rgba(63,233,213,1) 0%,rgba(121,9,162,1) 46%,rgba(213,50,73,1) 98%);
        img {
          width: 310px;
        } 
    }
    @media only screen  and (max-width: 600px) {
        background: linear-gradient(90deg,rgba(63,233,213,1) 0%,rgba(121,9,162,1) 35%,rgba(213,50,73,1) 86%);
        img {
          width: 310px;
        } 
    }
`;
export const ContainerLogo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    h1, .title-video {
        color: #E9ECEF;
        font-size: 24px;
        line-height: 36px;
    }
    .mobile-desc {
        display: inline;
        font-size: 14px;
    }
    .desktop-desc {
        display: none;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
            justify-content: space-between; 
            padding: 0 12px;
            span {
                font-size: 24px;
            }
            .desktop-desc {
                display: inline;
            }
            .mobile-desc {
                display: none;
            }
        }
    `}
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.sm}) and (max-width: ${theme.breakpoint.md}) {
            padding: 0 16px;
            justify-content: space-between;
            span {
                display: block;
                font-size: 16px;
            }
        }
    `}
    @media only screen  and (max-width: 320px) {
        padding: 0 8px;
    }
`;

const Divider = styled.div`
    background: ${p => p.theme.colors.gray2};
    height: ${p => p.high ? "40px" : "24px"};
    display: block;
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            display: none;
        }
    `}
`;
const MainVideoSection = styled.section`
    background: #e8eeee;
    @media only screen and (max-width: 1150px) {
      padding: 0;
    }
`;
const NewsRow = styled(Row)`
    &.row {
        margin: 20px 0;
    }
    .slick-slider {
        background: #e8eeee;
        width: 100%;
        button .slick-arrow{
            color: red !important;
        }
    }
    .slick-slider.container {
        padding: 24px;
    }
`
export const NewsContainer = styled(Container)`
    padding-left: 0;  
    padding-right: 0;
    .m-full-width{
        margin-left: 0;
    }
    ${({ theme }) => css`
        @media only screen and (min-width: ${theme.breakpoint.lg}) {
            padding-left: 12px;  
            padding-right: 12px;
        }
    `}
`;

const SpinnerContainer = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    min-height: 330px;
`;