import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AgroSpinner, Container } from 'agrofy-ui-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { formattedDate } from '../../utils/dateUtils';
import { Content } from '../MainVideo/MainVideo';
import ShareVideoButtons from '../MainVideo/ShareVideoButtons';
import { useStore } from '../../hooks/store'
import * as videosData from './GetVideosList6-5.json';
import { isEmpty } from '../../utils/helpers';
import './MainCardLive.scss';

const MainCardLive = (props) => {
  const { isCategoryPage, videoData } = props;
  const [data, setData] = useState(videosData.default);
  const stateFromStore = useStore()[0];
  const [playingVideo, setPlayingVideo] = useState(null);

  useEffect(() => {
    if(videoData){
      const firstVideo = videoData.videos[0];
      setPlayingVideo(firstVideo);
      videoData.videos.splice(0, 1);
      videoData.videos.push(firstVideo);
      setData(videoData);
    }
  }, [videoData]);

  const filteredArr = data.videos.filter(item => item); // rrmove null values

  const onHandleClick = (video) => {
    setPlayingVideo(video);    
  }
  const isPlayingVideo = item => item.url === stateFromStore.videoData.url;

  return (
    <>
      {playingVideo ? 
      <Principal className={`mainCardLiveStyle ${isCategoryPage ? 'isCategoryPage' : 'streamSection'}`}>
        <PlayingVideo className="playingVideo">
          <MainVideoContainer id="MainVideoContainer">
            <iframe
              src={`${playingVideo.url}?autoplay=1&mute=1&enablejsapi=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title="Embedded youtube"
            />
          </MainVideoContainer>
      
          <Description id="Description" isCategoryPage isModal={false} className="descriptionVideo">
            <WrapperMainTitle>
              <Content isModal={false}>
                <TitleMainVideo>{playingVideo.title}</TitleMainVideo>
                <Date isModal={false} isMainCard isLiveNews={false}>{`${formattedDate(playingVideo.publishedDate)}`}</Date>
                { isCategoryPage ? null : <ShareVideoButtons isCategoryPage={isCategoryPage} data={playingVideo}/> }
              </Content>
            </WrapperMainTitle>
          </Description>
        </PlayingVideo>
        {data.videos && <SliderSection id="SliderSection">
          <Slider {...settings} className="container slider-video">
            {filteredArr.map(item =>
              <SlickItem className={`video-carrusel-item ${item.id === playingVideo.id ? 'currentVideo' : ''}`} key={item.title} onClick={() => onHandleClick(item)}>
                <ContainerImg><Image src={item.imageUrl} className="slider-image"/></ContainerImg>
                <Title isPlaying={!isEmpty(stateFromStore) && isPlayingVideo(item)}>{item.title.slice(0, 100)}</Title>
              </SlickItem>
            )}
          </Slider>
        </SliderSection>}
      </Principal>
          : <Loader><AgroSpinner width={'50px'} height={'50px'} color='primary' /></Loader>}
    </>
  )
}

export default MainCardLive;

const settings = {
  infinite: true,
  speed: 600,
  slidesToShow:4,
  slidesToScroll: 1,
  autoplay: false,
  swipeToSlide: true,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  vertical: true,
  verticalSwiping: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: false
      }
    },
  ]
};

const PlayingVideo = styled.div`
  display: flex;
  width: 100%;
`;

const Loader = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    min-height: 660px;
`;
export const sharedStylesContainer = css`
    display: flex;
    flex-direction: ${p => p.isModal ? "row" : "column"};
    justify-content: ${p => p.isModal && "space-between"};
    align-items: ${p => p.isModal ? "center" : "stretch"};
`;
const MainVideoContainer = styled.div`
    position: relative;
    width: 60%;
    margin-bottom: 40px;
    a:focus, a:active {
        outline: none;
    };
    ${sharedStylesContainer};
    @media only screen and (max-width: 900px) {
      width: 100%;
      margin-bottom: 10px
    }
`;
const SliderSection = styled.section`
    width: 40%;
    max-width: 400px;
    min-width: 340px;
    display: flex;
    padding-top: 20px;
    @media only screen and (max-width: 1150px) {   
      padding-top: 0;
      width: 100%;
      max-width: 100%
    }
    a:focus, a:active {
        outline: none;
    }
    @media only screen and (max-width: 1150px) {
      width: 100%;
    }
`;
const SlickItem = styled.div`
    display: flex !important;
    color: #ffffff;
    backgroundcolor: #f1f3f5 !important;
    cursor: pointer;
    outline: none;
    @media only screen 
    and (min-width: 1024px) 
    and (max-height: 1366px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        label:nth-child(1) {
            font-size: 10px;
        }
        label:nth-child(2) {
            font-size: 12px;
        }
    }
`;
const ContainerImg = styled.div`
    margin:10px 0 10px 7px;
`;
const Image = styled.img`
    width: 133px;
    object-fit: cover;    
    @media only screen and (min-width: 900px) and (max-width: 1150px) {
      width: 90px;
    }
`;
const Title = styled.label`
    font-size: 14px !important;
    margin: 10px 5px 10px 15px;
    line-height: 16px;
    cursor: pointer;
    color: #63A105;
    opacity : ${p => p.isPlaying ? "0.7" : "1"};
    padding-right: 10px;
    font-family: "Barlow", sans-serif;
    overflow: hidden;
    @media only screen and (max-width: 1150px) {
      font-size: 15px !important;
      line-height: 18px;
    }
`;
export const LiveVideo = styled.div`
    display: flex;
    padding-top: 4px;
    margin-bottom: 4px;
    > span {
        color: ${p => p.theme.colors.green4};
        font-size: 12px;
        font-weight: 700;
        font-family: 'Open Sans';
    }
`;
const WrapperMainTitle = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 25px;
    @media only screen and (max-width: 900px) {   
        flex-direction: column;
        padding: 0 10px 10px 10px !important;
        margin-bottom: 20px;
        border-bottom: 2px solid #ADB5BD;
    }
`;
const TitleMainVideo = styled.h2`
    font-size: 26px;
    line-height: 40px;
    color: #63A105;
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ${({ theme }) => css`
        @media only screen and (max-width: 1150px) {
          padding-right: 20px;
          font-size: 22px;
          line-height: 32px;
        };
        @media only screen and (max-width: 860px) {
          padding-right: 0;
        }
    `}
`;
const Description = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${p => p.isModal ? "0 16px" : "0"};
    margin-top: ${p => p.isCategoryPage ? "0" : p.isModal ? "0" : "16px"};
    background: ${p => p.isCategoryPage ? "#e8eeee" : "inherit"};
`;
const Date = styled.div`
    padding-top: ${p => p.isModal ? "0 !important" : "20px"};
    text-transform: ${p => p.isLiveNews ? 'uppercase' : 'capitalize'};
    color: #51565e;
    font-family: "Open Sans";
    font-size: ${p => p.isMainCard ? "16px" : "12px"};
    line-height: 21px;
`;
const Principal = styled.div`
    background-color: #e8eeee;
    display: flex;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1150px) {
      flex-direction: column;
      padding: 0 20px;
    }       
`;

const PlayerContainer = styled.div`
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
    height: 0;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
`;