import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';
import TagManager from 'react-gtm-module';

const ShareVideoButtons = ({ isCategoryPage, isStreaming, data }) => {
    const [url, setUrl] = useState('');
    
    const manageGTM = label => {
        const tagManagerArgs = {
            dataLayer: {
            event: 'trackEvent',
            eventCategory: 'Videos',
            eventAction: 'Share Video',
            eventLabel: `From ${isCategoryPage ? "Category-":"Home-"}${label}`
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    };
    const title = data && data.title;

    useEffect(() => {
        if( window && window.location && window.location.href ) {
            setUrl(window.location.href);
        }
    }, []);

    return (
        <IconsWrapper className='btn-share-container'>
            <FacebookShareButton
                url={url}
                className="btn-share-news"
                title={title}
                quote={title}
                onClick={() => manageGTM(`Facebook`)}
            >
                <FacebookIcon size={32} round bgStyle={{ fill: "#495057" }} />
            </FacebookShareButton>
            <TwitterShareButton
                url={url}
                className="btn-share-news"
                title={title}
                onClick={() => manageGTM(`Twitter`)}
            >
                <TwitterIcon size={32} round bgStyle={{ fill: "#495057" }} />
            </TwitterShareButton>
            <LinkedinShareButton
                url={url}
                className="btn-share-news"
                title={title}
                quote={title}
                onClick={() => manageGTM(`Linkedin`)}
                >
                <LinkedinIcon size={32} round bgStyle={{ fill: "#495057" }} />
            </LinkedinShareButton>
            <WhatsappShareButton
                url={url}
                className="btn-share-news"
                title={title}
                separator=" "
                onClick={() => manageGTM(`Whatsapp`)}
                >
                <WhatsappIcon size={32} round bgStyle={{ fill: "#495057" }} />
            </WhatsappShareButton>
        </IconsWrapper>
    )
}

export default ShareVideoButtons;

const IconsWrapper = styled.div`
    display: flex;
    margin-top: 16px;
    button:not(:last-child) {
        margin-right: 16px;
    }
`;

