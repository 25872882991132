/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useContext } from 'react';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import StandarCard, { LoaderCard } from '../../components/standarCard/StandarCard';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../components/searchBar/SearchBar';
import Carousel from '../../components/Carousel/Carousel';
import DarkModeContext from '../../atoms/DarkModeContext/DarkModeContext';
import './SectionVideosTemplate.scss';
// import TagManager from 'react-gtm-module';

const SectionVideosTemplate = ({
  data,
  category,
  classes,
  sectionTitle,
  bannersData,
  loading,
}) => {
  const { t } = useTranslation();
  const news = data && data.news;
  const [banner0Loaded, setbanner0Loaded] = useState('no-loaded-banner');
  const [middle1Loaded, setMiddle1Loaded] = useState('no-loaded-banner-mobile');
  const [middle2Loaded, setMiddle2Loaded] = useState('no-loaded-banner-mobile');
  const [fairData, setFairData] = useState(null);
  const { isDarkMode, toggleMode } = useContext(DarkModeContext);

  const countryName = process.env.COUNTRY_NAME || window.env.COUNTRY_NAME;
  const isBrasil = countryName === 'Brasil';
  
  let dfpNetworkID =
    process.env.DFP_NETWORK_ID ||
    (typeof window !== 'undefined' && window.env.DFP_NETWORK_ID);  

  const standardCard = (value, isMini) => {
    return isMini ? (
      <div className="mb-35">
        <StandarCard
          clampText={5}
          miniStandarCard
          link={value && value.url}
          image={value && value.image}
          category={value && value.category}
          imageTitle={value.title}
          body={value.title}
          loading={!news || !Array.isArray(news) || loading}
          isDarkMode={isDarkMode}
          toggleMode={toggleMode}
          exclusive={value && value.exclusive}
        ></StandarCard>
      </div>
    ) : (
      <div className={`mb-35 ${isSingleCard && 'single-card'}`}>
        <StandarCard
          clampText={4}
          link={value && value.url}
          image={value && value.image}
          category={value && value.category}
          imageTitle={value && value.title}
          body={value && value.title}
          loading={!news || !Array.isArray(news) || loading}
          isDarkMode={isDarkMode}
          toggleMode={toggleMode}
          exclusive={value && value.exclusive}
        ></StandarCard>
      </div>
    );
  };
  const TitleLoader = ({ style }) => (
    <div className="loader pr-0 animated-background" style={{ ...style }}></div>
  );
  const isSingleCard = news && news.length === 1;

  const fairDesc = (fair) => {
    setFairData(fair);
  };

  const mkpUrl = isBrasil
    ? 'https://www.agrofy.com.br'
    : 'https://www.agrofy.com.ar';

  return (
    <section
      id="section-template"
      className={`d-flex flex-wrap mb-36 ${classes ? classes : ''}`}
    >
      <div className="container d-flex flex-wrap p-0 pb-35">
            
        <div className="row keep-reading">
          <div className="col-lg-4">
            {sectionTitle ? (
              loading ? (
                <TitleLoader
                  style={{ minHeight: '34px', marginTop: '12px', width: '50%' }}
                />
              ) : (
                <h2 className="mb-20 mt-10">{sectionTitle}</h2>
              )
            ) : null}
            {!loading && news && news[0] && typeof news[0] !== 'undefined' ? (
              standardCard(news[0], false)
            ) : loading ? (
              <div className="mb-35">
                <LoaderCard />
              </div>
            ) : null}
          </div>
          {/* {!(!news || !Array.isArray(news) || loading) &&
            <> */}
          <div className="col-lg-4">
            {!loading && news && news[1] && typeof news[1] !== 'undefined' ? (
              standardCard(news[1], false)
            ) : loading ? (
              <div className="mb-35">
                <LoaderCard />
              </div>
            ) : null}
          </div>
          <div className="col-lg-4">            
            {!loading && news && news[2] && typeof news[1] !== 'undefined' ? (
              standardCard(news[2], false)
            ) : loading ? (
              <div className="mb-35">
                <LoaderCard />
              </div>
            ) : null}            
          </div>
        </div>
        <div className={`section-template__banner col-12 m-full-width px-0 `}>
          <div className="dfp-banner stripped">
            {!bannersData ? (
              <div className="banner b-728-90" />
            ) : (
              <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                <AdSlot
                  adUnit={`${bannersData[0].adUnit}`}
                  sizes={bannersData[0].sizes}
                  onSlotRender={(e) =>
                    e.event.isEmpty ? '' : setbanner0Loaded('loaded-banner')
                  }
                />
              </DFPSlotsProvider>
            )}
          </div>
        </div>
        <div className="col d-flex flex-wrap mb-36 px-0">
          <div className="col-5 d-none d-lg-block px-0">
            <div className="dfp-banner stripped">
              {!bannersData ? (
                <div className="banner b-300-600" />
              ) : (
                <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                  <AdSlot
                    adUnit={`${bannersData[3].adUnit}`}
                    sizes={bannersData[3].sizes}
                  />
                </DFPSlotsProvider>
              )}
            </div>
          </div>
          <div className="col-lg-7 d-flex flex-wrap px-m-0 pr-lg-0">
            {
              <div className="d-flex p-0 row">
                {!(!news || !Array.isArray(news) || loading)
                  ? news.slice(3, 7).map((value, index) => (
                      <div className="col-lg-6" key={index}>
                        {standardCard(value, true)}
                      </div>
                    ))
                  : loading
                  ? ['news4', 'news5', 'news6', 'news7'].map((val, ind) => (
                      <div className="col-lg-6" key={ind}>
                        <div className="mb-35">
                          <LoaderCard miniStandarCard />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            }
          </div>
        </div>
         
        <div className="d-flex flex-wrap p-0">
          {!(!news || !Array.isArray(news) || loading)
            ? news.slice(7, 10).map((value, index) => (
                <div className="col-lg-4 px-lg-15 p-sm-0" key={index}>
                  {standardCard(value, true)}
                </div>
              ))
            : loading
            ? ['news8', 'news9', 'news10'].map((val, ind) => (
                <div className="col-lg-4" key={ind}>
                  <div className="mb-35">
                    <LoaderCard miniStandarCard />
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="d-flex p-0 row m-lg-0">
          <div className="col-lg-4 px-lg-15">
            {!loading && news && news[10] && typeof news[10] !== 'undefined' ? (
              standardCard(news[10], true)
            ) : loading ? (
              <LoaderCard miniStandarCard />
            ) : null}
          </div>
          {/*<div className={`col-lg-8 mx-0 mb-35 m-full-width ${middle1Loaded}`}>
            <div className="dfp-banner stripped">
              {!bannersData ? (
                <div className="banner b-300-250" />
              ) : (
                <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                  <AdSlot
                    adUnit={`${bannersData[1].adUnit}`}
                    sizes={bannersData[1].sizes}
                    onSlotRender={(e) =>
                      e.event.isEmpty
                        ? ''
                        : setMiddle1Loaded('loaded-banner-mobile')
                    }
                  />
                </DFPSlotsProvider>
              )}
            </div>
          </div>*/}
        </div>
        <div className="middle-banner p-0 w-100">
          {/*<div className={`col-lg-8 mb-35 m-full-width ${middle2Loaded}`}>
            <div className="dfp-banner stripped">
              {!bannersData ? (
                <div className="banner b-300-250" />
              ) : (
                <DFPSlotsProvider dfpNetworkId={dfpNetworkID}>
                  <AdSlot
                    adUnit={`${bannersData[2].adUnit}`}
                    sizes={bannersData[2].sizes}
                    onSlotRender={(e) =>
                      e.event.isEmpty
                        ? ''
                        : setMiddle2Loaded('loaded-banner-mobile')
                    }
                  />
                </DFPSlotsProvider>
              )}
            </div>
          </div>*/}
          <div className="col-lg-4 single">
            {!loading && news && news[11] && typeof news[11] !== 'undefined' ? (
              standardCard(news[11], true)
            ) : loading ? (
              <LoaderCard miniStandarCard />
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-wrap p-0">
          {!loading && news
            ? news.slice(12, 15).map((value, index) => (
                <div className="col-lg-4 px-lg-15 p-sm-0" key={index}>
                  {standardCard(value, true)}
                </div>
              ))
            : loading
            ? ['news13', 'news14', 'news15'].map((val, ind) => (
                <div className="col-lg-4 px-lg-15 p-sm-0" key={ind}>
                  <div className="mb-35">
                    <LoaderCard miniStandarCard />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </section>
  );
};

SectionVideosTemplate.defaultProps = {
  data: Array(15)
    .fill()
    .map((value) => {
      return {
        miniStandarCard: '',
        littleTex: '',
        link: 'https://news.agrofy.com.ar/noticia/180965/campo-cristina-es-jugada-electoral-que-no-cambia-nada',
        image:
          'https://argentina.agrofystatic.com/media/static/listing/logo-placeholder.jpg',
        imageTitle: 'Agrofy',
        category: 'Granos',
        body: 'Agrofy',
      };
    }),
};

export default SectionVideosTemplate;
